// import React, { useState, useEffect } from "react";
// import styled, { keyframes, css } from "styled-components";


// function CarouselLogo() {

//   const [posts, setPosts] = useState([]);
//   const fetchPosts = () => {
//     fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/testimonial`)
//       .then((res) => res.json())
//       .then((resData) => {
//         setPosts(resData);
//       })
//       .catch((err) => {
//         console.error('Error fetching blog posts:', err);
//       });
//   };

//   useEffect(() => {
//     fetchPosts();
//   }, []);

//   return (
//     <div className="container">
//     <AppContainer>
//       <Wrapper>
//         <Marquee>
//           <MarqueeGroup items={posts.length}>
//             {posts.map((currentElement) => {
//               const { id, organisation_logo } = currentElement;
//               return (
//                 <ImageGroup key={id}>
//                   <Image src={organisation_logo} alt="" />
//                 </ImageGroup>
//               );
//             })}
//             {posts.map((currentElement) => {
//               const { id, organisation_logo } = currentElement;
//               return (
//                 <ImageGroup key={id}>
//                   <Image src={organisation_logo} alt="" />
//                 </ImageGroup>
//               );
//             })}
//           </MarqueeGroup>
//         </Marquee>
//       </Wrapper>
//     </AppContainer>
//   </div>
//   );
// }

// export default CarouselLogo;

// const scroll = keyframes`
//   0% { transform: translateX(0); }
//   100% { transform: translateX(-100%); }
// `;

// const AppContainer = styled.div`
//   color: #000000;
//   height: 25vh;
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   @media (max-width: 1130px) {
//     height: 15vh;
//   }
// `;

// const Wrapper = styled.div`
//   overflow: hidden;
//   width: 100%;
//   height: 80%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
//     @media (max-width: 580px) {
//      height: 100%;
//   }
// `;

// const Marquee = styled.div`
//   display: flex;
//   width: 100%;
//   height: 100%;
//   overflow: hidden;
//   user-select: none;
// `;

// const MarqueeGroup = styled.div`
//   display: flex;
//   gap: 3em;
//   height: 100%;
//   width: calc(180px * ${props => props.items * 2}); /* Adjusting width dynamically */
//   animation: ${scroll} 30s linear infinite;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   @media (max-width: 980px) {
//     animation: ${scroll} 30s linear infinite;
//   }
// `;

// const ImageGroup = styled.div`
//   width: 180px;
//   height: 100px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

// const Image = styled.img`
//   width: 100%; /* Ensures the image takes the full width of the container */
//   height: 100%; /* Ensures the image takes the full height of the container */
//   object-fit: contain; /* Keeps the image's aspect ratio */
//   display: block; /* Removes space below image */
// `;




// import React, { useState, useEffect } from "react";
// import styled, { keyframes, css } from "styled-components";
// import './css/carousellogo.css'

// function CarouselLogo() {

//   const [posts, setPosts] = useState([]);
//   const fetchPosts = () => {
//     fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/testimonial`)
//       .then((res) => res.json())
//       .then((resData) => {
//         setPosts(resData);
//       })
//       .catch((err) => {
//         console.error('Error fetching blog posts:', err);
//       });
//   };

//   useEffect(() => {
//     fetchPosts();
//   }, []);

//   return (<div className="container">
//      <AppContainer>
//       <Wrapper>
//         <div class="logos">
//           <div class="logos-slide">
//             {posts.map((currentElement) => {
//               const { id, organisation_logo } = currentElement;
//               return (

//                 <img src={organisation_logo} />
//               );
//             })}
//           </div>

//           <div class="logos-slide">
//             {posts.map((currentElement) => {
//               const { id, organisation_logo } = currentElement;
//               return (

//                 <img src={organisation_logo} />
//               );
//             })}
//           </div>
//         </div>
//       </Wrapper>
//     </AppContainer>
//   </div>
//   );
// };

// export default CarouselLogo;
// const Wrapper = styled.div`
//   overflow: hidden;
//   width: 100%;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
//     @media (max-width: 580px) {
//      height: 100%;
//   }
// `;
// const AppContainer = styled.div`
//   color: #000000;
//   height: 25vh;
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   @media (max-width:780px) {
//     height: 15vh;
//   }
// `;

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import './css/carousellogo.css'

function CarouselLogo() {
  const [posts, setPosts] = useState([]);
  const logosSlideWrapperRef = useRef(null);
  const logosSlideRef = useRef(null);

  const fetchPosts = () => {
    fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/testimonial`)
      .then((res) => res.json())
      .then((resData) => {
        setPosts(resData);
      })
      .catch((err) => {
        console.error('Error fetching blog posts:', err);
      });
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  // useEffect(() => {
  //   if (logosSlideWrapperRef.current && logosSlideRef.current) {
  //     const logosSlideWrapper = logosSlideWrapperRef.current;
  //     const logosSlide = logosSlideRef.current;
  //     const clone = logosSlide.cloneNode(true);
  //     logosSlideWrapper.appendChild(clone);

  //     function startMarquee() {
  //       const scrollWidth = logosSlideWrapper.scrollWidth / 2;

  //       logosSlideWrapper.style.transform = `translateX(-${scrollWidth}px)`;

  //       logosSlideWrapper.addEventListener('transitionend', () => {
  //         logosSlideWrapper.style.transition = 'none';
  //         logosSlideWrapper.style.transform = `translateX(0)`;
  //         setTimeout(() => {
  //           logosSlideWrapper.style.transition = 'transform 40s linear';
  //           startMarquee();
  //         }, 0);
  //       });
  //     }

  //     startMarquee();
  //   }
  // }, [posts]); 
  // useEffect(() => {
  //   if (logosSlideWrapperRef.current && logosSlideRef.current) {
  //     const logosSlideWrapper = logosSlideWrapperRef.current;
  //     const logosSlide = logosSlideRef.current;
  //     const clone = logosSlide.cloneNode(true);
  //     logosSlideWrapper.appendChild(clone);

  //     function startMarquee() {
  //       const scrollWidth = logosSlideWrapper.scrollWidth / 2;
  //       const transitionDuration = window.innerWidth <= 768 ? '25s' : '30s'; 
  //       console.log("transitionDuration",transitionDuration)
  //       logosSlideWrapper.style.transition = `transform ${transitionDuration} linear`;
  //       logosSlideWrapper.style.transform = `translateX(-${scrollWidth}px)`;
  //       logosSlideWrapper.addEventListener('transitionend', () => {
  //         logosSlideWrapper.style.transition = 'none';
  //         logosSlideWrapper.style.transform = `translateX(0)`;
  //         setTimeout(() => {
  //           logosSlideWrapper.style.transition = `transform ${transitionDuration} linear`;
  //           startMarquee();
  //         }, 0);
  //       });
  //     }

  //     startMarquee();
  //   }
  // }, [posts]);
  useEffect(() => {
    if (logosSlideWrapperRef.current && logosSlideRef.current) {
      const logosSlideWrapper = logosSlideWrapperRef.current;
      const logosSlide = logosSlideRef.current;
      const clone = logosSlide.cloneNode(true);
      logosSlideWrapper.appendChild(clone);
      function startMarquee() {
        const scrollWidth = logosSlideWrapper.scrollWidth / 2;
        const transitionDuration = window.innerWidth <= 768 ? '25s' : '30s';
        logosSlideWrapper.style.transition = `transform ${transitionDuration} linear`;
        logosSlideWrapper.style.transform = `translateX(-${scrollWidth}px)`;
        logosSlideWrapper.addEventListener('transitionend', () => {
          logosSlideWrapper.style.transition = 'none';
          logosSlideWrapper.style.transform = `translateX(0)`;
          setTimeout(() => {
            logosSlideWrapper.style.transition = `transform ${transitionDuration} linear`;
            startMarquee();
          }, 0);
        });
      }

      startMarquee();
    }
  }, [posts]);

  return (
    <div className="container">
      <AppContainer>
        <Wrapper>
          <div className="logos">
            <div className="logos-slide-wrapper" ref={logosSlideWrapperRef}>
              <div className="logos-slide" ref={logosSlideRef}>
                {posts.map((currentElement) => {
                  const { id, organisation_logo } = currentElement;
                  return (
                    <img key={id} src={organisation_logo} alt="Logo" />
                  );
                })}
                {posts.map((currentElement) => {
                  const { id, organisation_logo } = currentElement;
                  return (
                    <img key={id} src={organisation_logo} alt="Logo" />
                  );
                })}
              </div>
            </div>
          </div>
        </Wrapper>
      </AppContainer>
    </div>
  );
};

export default CarouselLogo;

const Wrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 580px) {
    height: 100%;
  }
`;

const AppContainer = styled.div`
  color: #000000;
  height: 25vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 780px) {
    height: 15vh;
  }
`;
