import React from 'react'
import './leftnavigation.css'
import { HashLink as Link } from "react-router-hash-link";
export default function Leftnavigation() {
  return (
    <div className='left-div'>
      <Link to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/antrazal/admin/testimonial">
        <h1>Testimonial</h1>
      </Link>
      <Link to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/antrazal/admin/service">
        <h1>Service</h1>
      </Link>
      <Link to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/antrazal/admin/blog">
        <h1>Blog</h1>
      </Link>
      <Link to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/antrazal/admin/areasOfOurExpertise">
        <h1>Areas of our expertise</h1>
      </Link>
      <Link to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/antrazal/admin/caseStudy">
        <h1>Case Study</h1>
      </Link>
      <Link to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/antrazal/admin/achievements">
        <h1>achievements</h1>
      </Link>
      <Link to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/antrazal/admin/getpublicUrlOfImges">
        <h1>Get Public url of Images</h1>
      </Link>
      <Link to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/antrazal/admin/contactUs">
        <h1>contactUs</h1>
      </Link>
      <Link to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/antrazal/admin/leets">
        <h1>Leets</h1>
      </Link>
      <Link to="/DjUcNcbvQ0PZ9KGjn3t6OEmC7lq5dO0ckborXyZVTnU0Gd5OPJMjpwLKC48QwHAqcXLWVBnXC5fFwMjNzCNGp5C8fcZ9U7friZIp/antrazal/admin/employeManagement">
        <h1>Employee Management</h1>
      </Link>
    </div>
  )
}
