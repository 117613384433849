import React, { useState, useEffect } from "react";
import './css/banner.css'
const Banner = () => {

	const [loopNum, setLoopNum] = useState(0);
	const [isDeleting, setIsDeleting] = useState(false);
	const [text, setText] = useState('');
	const [delta, setDelta] = useState(300 - Math.random() * 100);
	const [index, setIndex] = useState(1);
	const toRotate = ['Transform Digitally', 'Migrate Digitally'];
	const period = 2000;

	useEffect(() => {
		let ticker = setInterval(() => {
			tick();
		},
			delta);
		return () => { clearInterval(ticker) };
	}, [text])

	const tick = () => {
		let i = loopNum % toRotate.length;
		let fullText = toRotate[i];
		let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);
		setText(updatedText);
		if (isDeleting) {
			setDelta(prevDelta => prevDelta / 2);
		}

		if (!isDeleting && updatedText === fullText) {
			setIsDeleting(true);
			setIndex(prevIndex => prevIndex - 1);
			setDelta(period);
		}

		else if (isDeleting && updatedText === '') {
			setIsDeleting(false);
			setLoopNum(loopNum + 1);
			setIndex(1);
			setDelta(500);
		}

		else {
			setIndex(prevIndex => prevIndex + 1);
		}
	}

	return (
	
		<section className="" id="home">
			<div style={{}} className="newbanner-inner-div">
				
				<div className="newaligh-items-center newbanner-row-div">
					<div className="newbanner-left-div">
						<div className="newbanner">
							<h1 style={{ color: "black" }} className='newmargin-bottom newtopHeaderHeading'><span style={{ color: "rgb(0 54 219)" }} className="HeadingSpanClass">Our Authentic</span> <br /> expertise powering <br />your digitalization journey.</h1>
							<div className="newbtn-home-container ">
								<button className="newbtns" ><b ><a href="https://calendly.com/info-gmgd/30min" target="_blank">Book a free Consultation</a></b></button>
							</div>
						</div>
					</div>
					<div className="newbanner-right-div">
						<div style={{}} className="newbanner-right-innerdiv">
							<div className="newbannerServiceSetction">
								<h1 className="newimplemantationDomHeading" style={{color:"rgb(0 54 219)"}}>Successful implementations ...</h1>
								<div className="newbannerServiceSetction-inner-div">
									<h1 style={{color:'#2d58ed'}}>200+</h1>
									<div className='newspanClassForImplemantation' style={{color:'#2d58ed'}}>
										Salesforce CRM
									</div>
								</div>
								<div className="newbannerServiceSetction-inner-div">
									<h1 style={{    color: '#0036db'}}>150+</h1>
									<div className='newspanClassForImplemantation' style={{    color: '#0036db'}}>	Marketing Cloud</div>
								</div>
								<div className="newbannerServiceSetction-inner-div" >
									<h1 style={{    color: "#1012a2"}}>50+</h1>
									<div className='newspanClassForImplemantation'  style={{    color: "#1012a2"}}>
										Vlocity/Industries
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Banner;