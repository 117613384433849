import React from 'react';

import './Loader.css';

const loader = props => (
  <div className="adminloader">
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default loader;
