import React, { Component } from 'react';
import Input from "../../components/Form/Input/Input";
import Button from "../../components/Button/Button";
import Auth from '../Auth/Auth';
import FilePicker from '../../components/Form/Input/FilePicker';
import Post from '../../components/Feed/Post/Post';
import Loader from '../../components/Loader/Loader';

/*
*********************************************************************************************************
*  @Parent Component           :    Admin Panel
*  @React Component Name       :    Testimonial
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    04-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       Testimonial Admin Pages 
********************************************************************************************************
*/

export default class Testimonial extends Component {

    /*
    *********************************************************************************************************
    *   @React Component Name       :       Testimonial
    *   @Method                     :       
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       initialized variables
    *********************************************************************************************************
    */

    state = {
        testimonialForm: {
            client_name: {
                value: '',
                valid: false,
                validators: [],
            },
            designation: {
                value: '',
                valid: false,
                validators: [],
            },
            organisation: {
                value: '',
                valid: false,
                touched: false,
                validators: [],
            },
            photo: {
                value: '',
                valid: false,
                touched: false,
                validators: [],
            },
            organisation_logo: {
                value: '',
                valid: false,
                validators: [],
            },
            content: {
                value: '',
                valid: false,
                validators: [],
            },
        },
        editPostBtn: false,
        editPostId: null,
        formIsValid: false,
        formShow: false,
        posts: [],
        postsLoading: false,
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       Testimonial
    *   @Method                     :       inputChangeHandler
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       this method vlalidates for make changes on the input
    *********************************************************************************************************
    */

    inputChangeHandler = (input, value) => {
        this.setState(prevState => {
            let isValid = true;
            for (const validator of prevState.testimonialForm[input].validators) {
                isValid = isValid && validator(value);
            }
            const updatedForm = {
                ...prevState.testimonialForm,
                [input]: {
                    ...prevState.testimonialForm[input],
                    valid: isValid,
                    value: value,
                },
            };
            let formIsValid = true;
            for (const inputName in updatedForm) {
                formIsValid = formIsValid && updatedForm[inputName].valid;
            }
            return {
                testimonialForm: updatedForm,
                formIsValid: formIsValid,
            };
        });
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       Testimonial
    *   @Method                     :       inputBlurHandler
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       this method keep track previous input data 
    *********************************************************************************************************
    */

    inputBlurHandler = input => {
        this.setState(prevState => {
            return {
                testimonialForm: {
                    ...prevState.testimonialForm,
                    [input]: {
                        ...prevState.testimonialForm[input],
                        touched: true,
                    },
                },
            };
        });
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       Testimonial
    *   @Method                     :       toggleForm
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       this method toogle the form 
    *********************************************************************************************************
    */

    toggleForm = () => {
        this.setState(prevState => ({
            formShow: !prevState.formShow,
        }));
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       Testimonial
    *   @Method                     :       postInputChangeHandler
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       this method using for the uploade the images in s3 bucket
    *********************************************************************************************************
    */

    postInputChangeHandler = async (input, value, files) => {
        if (files && files.length > 0) {
            const formData = new FormData();
            formData.append('file', files[0]);
            try {
                const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/upload`, {
                    method: 'POST',
                    body: formData,
                });
                if (response.ok) {
                    const { url } = await response.json();
                    const fieldToUpdate = input === 'photo' ? 'photo' : 'organisation_logo';
                    this.setState(prevState => ({
                        testimonialForm: {
                            ...prevState.testimonialForm,
                            [fieldToUpdate]: {
                                ...prevState.testimonialForm[fieldToUpdate],
                                valid: true,
                                value: url,
                            },
                        },
                        formIsValid: true,
                    }));
                } else {
                    console.error('Failed to upload image');
                }
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       Testimonial
    *   @Method                     :       onTestimonial
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       this method take all input data and store in the server  or update the created testimonial 
    *********************************************************************************************************
    */

    onTestimonial = event => {
        event.preventDefault();
        const {
            client_name,
            designation,
            photo,
            organisation,
            organisation_logo,
            content,
        } = this.state.testimonialForm;
        const requestData = {
            client_name: client_name.value,
            designation: designation.value,
            photo: photo.value,
            content: content.value,
            organisation: organisation.value,
            organisation_logo: organisation_logo.value,
            createdBy: localStorage.getItem('userId'),
            updatedBy: localStorage.getItem('userId'),
        };
        if (this.state.editPostBtn) {
            fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/testimonial/` + this.state.editPostId, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            })
                .then(res => res.json())
                .then(data => {
                    this.toggleForm();
                    this.fetchPosts();
                    this.setNullAllInputFields();
                    this.setState({})
                    this.setState(prevState => ({
                        editPostBtn: false,
                        editPostId: null,
                    }));
                })
                .catch(err => {
                    console.error('Error updating testimonial:', err);
                });
        } else {
            fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/testimonial`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            })
                .then(res => res.json())
                .then(data => {
                    this.toggleForm();
                    this.fetchPosts();
                    this.setNullAllInputFields();
                })
                .catch(err => {
                    console.error('Error creating testimonial:', err);
                });
        }
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       Testimonial
    *   @Method                     :       setNullAllInputFields
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       this method sett all value null 
    *********************************************************************************************************
    */

    setNullAllInputFields() {
        this.setState(prevState => ({
            testimonialForm: {
                ...prevState.testimonialForm,
                client_name: { ...prevState.testimonialForm.client_name, value: '' },
                designation: { ...prevState.testimonialForm.designation, value: '' },
                photo: { ...prevState.testimonialForm.photo, value: '' },
                organisation: { ...prevState.testimonialForm.organisation, value: '' },
                organisation_logo: { ...prevState.testimonialForm.organisation_logo, value: '' },
                content: { ...prevState.testimonialForm.content, value: '' },
            },
        }));
    }

    /*
*********************************************************************************************************
*   @React Component Name       :       Testimonial
*   @Method                     :       componentDidMount
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       this methid call the function on change in the page 
*********************************************************************************************************
*/

    componentDidMount() {
        this.fetchPosts();
    }

    /*
    *********************************************************************************************************
    *   @React Component Name       :       Testimonial
    *   @Method                     :       fetchPosts
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       fetch the testimonial data from the server 
    *********************************************************************************************************
    */

    fetchPosts = () => {
        this.setState({ postsLoading: true });
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/testimonial`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(res => {
                if (res.status !== 200) {
                    throw new Error('Failed to fetch testimonials.');
                }
                return res.json();
            })
            .then(resData => {
                this.setState({ status: resData.status, posts: resData, postsLoading: false });
            })
            .catch(error => {
                console.error('Error fetching testimonials:', error);
            });
    };

    /*
*********************************************************************************************************
*   @React Component Name       :       Testimonial
*   @Method                     :       deletePostHandler
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       this method delete the testimonial data from the server 
*********************************************************************************************************
*/

    deletePostHandler = postId => {
        this.setState({ postsLoading: true });
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/testimonial/` + postId, {
            method: 'DELETE',
            headers: {
                Authorization: 'Bearer ' + this.props.token,
            },
        })
            .then(res => {
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error('Deleting a post failed!');
                }
                // console.log("Post deleted successfully");
                this.fetchPosts();
            })
            .catch(err => {
                console.error('Error deleting post:', err);
                this.setState({ postsLoading: false });
            });
    };

    /*
*********************************************************************************************************
*   @React Component Name       :       Testimonial
*   @Method                     :       fetchSinglePost
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       this methid call retu4rn the single testimonial data by Id 
*********************************************************************************************************
*/

    fetchSinglePost = postId => {
        this.setState({ postsLoading: true });
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/testimonial/` + postId)
            .then(res => {
                if (res.status !== 200) {
                    throw new Error('Failed to fetch testimonials.');
                }
                return res.json();
            })
            .then(resData => {
                this.setState(prevState => ({
                    testimonialForm: {
                        ...prevState.testimonialForm,
                        client_name: {
                            ...prevState.testimonialForm.client_name,
                            value: resData.client_name
                        },
                        designation: {
                            ...prevState.testimonialForm.designation,
                            value: resData.designation
                        },
                        photo: {
                            ...prevState.testimonialForm.photo,
                            value: resData.photo
                        },
                        organisation: {
                            ...prevState.testimonialForm.organisation,
                            value: resData.organisation
                        },
                        organisation_logo: {
                            ...prevState.testimonialForm.organisation_logo,
                            value: resData.organisation_logo
                        },
                        content: {
                            ...prevState.testimonialForm.content,
                            value: resData.content
                        },
                    }
                }));
            })
            .catch(error => {
                console.error('Error fetching testimonial:', error);
            });
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       Testimonial
    *   @Method                     :       editPost
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       this methid invoke when we want ot update the testimonial or it render the all previous data on the ibput section
    *********************************************************************************************************
    */

    editPost = postId => {
        this.setState({
            editPostBtn: true,
            editPostId: postId
        }, () => {
            this.fetchSinglePost(postId);
            this.toggleForm();
        });
    };

    render() {
        return (
            <div className="main-div-login">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '1rem 0 ',
                    }}
                >
                    <button onClick={this.toggleForm} className='buttonCreatePost'>
                        {this.state.formShow ? ("Close New Post") : ("Create New Post")}
                    </button>
                </div>
                {this.state.formShow && (
                    <Auth>
                        <form onSubmit={this.onTestimonial}>
                            <Input
                                id="client_name"
                                label="Client Name"
                                type="text"
                                control="input"
                                onChange={this.inputChangeHandler}
                                value={this.state.testimonialForm['client_name'].value}
                                valid={this.state.testimonialForm['client_name'].valid}
                                touched={this.state.testimonialForm['client_name'].touched}
                            />
                            <Input
                                id="designation"
                                label="Client Designation"
                                type="text"
                                control="input"
                                onChange={this.inputChangeHandler}
                                value={this.state.testimonialForm['designation'].value}
                                valid={this.state.testimonialForm['designation'].valid}
                                touched={this.state.testimonialForm['designation'].touched}
                            />
                            <Input
                                id="organisation"
                                label="Client organisation"
                                type="text"
                                control="input"
                                onChange={this.inputChangeHandler}
                                value={this.state.testimonialForm['organisation'].value}
                                valid={this.state.testimonialForm['organisation'].valid}
                                touched={this.state.testimonialForm['organisation'].touched}
                            />

                            <FilePicker
                                id="photo"
                                label="Client photo"
                                control="input"
                                onChange={this.postInputChangeHandler}
                                onBlur={this.inputBlurHandler.bind(this, 'photo')}
                                value={this.state.testimonialForm['photo'].value}
                                valid={this.state.testimonialForm['photo'].valid}
                                touched={this.state.testimonialForm['photo'].touched}
                            />
                            <FilePicker
                                id="organisation_logo"
                                label="Client organisation logo"
                                control="input"
                                onChange={this.postInputChangeHandler}
                                value={this.state.testimonialForm['organisation_logo'].value}
                                onBlur={this.inputBlurHandler.bind(this, 'organisation_logo')}
                                valid={this.state.testimonialForm['organisation_logo'].valid}
                                touched={this.state.testimonialForm['organisation_logo'].touched}
                            />
                            <Input
                                id="content"
                                label="content"
                                control="textarea"
                                rows="5"
                                onChange={this.inputChangeHandler}
                                valid={this.state.testimonialForm['content'].valid}
                                touched={this.state.testimonialForm['content'].touched}
                                value={this.state.testimonialForm['content'].value}
                            />
                            {this.state.editPostBtn ? (
                                <Button design="raised" type="submit" loading={this.props.loading}>
                                    Update Post
                                </Button>
                            ) : (
                                <Button design="raised" type="submit" loading={this.props.loading}>
                                    Create Post
                                </Button>
                            )}
                        </form>
                    </Auth>
                )}
                <Auth>
                    {this.state.postsLoading && <Loader />}
                    {this.state.posts.length <= 0 && !this.state.postsLoading ? (
                        <p style={{ textAlign: 'center' }}>No posts found.</p>
                    ) : null}
                    {this.state.posts.map(post => (
                        <Post
                            key={post.id}
                            id={post.id}
                            title={post.client_name}
                            content={post.content}
                            onStartEdit={() => this.editPost(post.id)}
                            onDelete={() => this.deletePostHandler(post.id)}
                        />
                    ))}
                </Auth>
            </div>
        );
    }
}
