import React, { useEffect, useState } from 'react'
import './../Components/css/blog.css'
import OtherServices from '../Components/OtherServices';
import SetMeeting from '../Components/SetMeeting'
import CarouselLogo from '../Components/CarouselLogo';
import { Link } from 'react-router-dom';

/*
*********************************************************************************************************
*  @Parent Component           :    App home
*  @React Component Name       :    Blog
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    05-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       Blog main page
********************************************************************************************************
*/

export default function Blog() {
	const [posts, setPosts] = useState([]);

	/*
	*********************************************************************************************************
	*   @React Component Name       :       Blog
	*   @Method                     :       useEffect
	*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
	*   @Company                    :       Antrazal
	*   @Description                :       render the data on changes or lend on the page 
	*********************************************************************************************************
	*/

	useEffect(() => {
		fetchPosts();
	}, []);

	/*
*********************************************************************************************************
*   @React Component Name       :       Blog
*   @Method                     :       fetchPosts
*   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*   @Company                    :       Antrazal
*   @Description                :       fetch the data from the server  
*********************************************************************************************************
*/

	const fetchPosts = () => {
		fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/blog`)
			.then((res) => res.json())
			.then((resData) => {
				// console.log(resData)
				setPosts(resData);
			})
			.catch((err) => {
				console.error('Error fetching blog posts:', err);

			});
	};

	return (
		<>
			<div className='container blog-page'>
				<h1 className='blog-page-heading'>Latest  Blogs </h1>
				<div className='blog-main-page-grid'>
					{
						posts.map((metadata) => (
							<Link to={{
								pathname: "/blog/page",
								search: `?viewmore=${metadata.title}`,
							}} className='blog-anchortag'>
								<div className='blog-card-main-div'>
									<div className='blog-image-div'><img src={metadata.image} alt="" /></div>
									<div className='blog-card-detail-div'>
										<h1 className='para-limit1'>{metadata.title}</h1>
										<p className='blog-card-author-name'>{metadata.authorName}</p>
										<p className='blog-card-creating-date'>{metadata.created_date}</p>
									</div>
								</div>
							</Link>
						))
					}
				</div>
			</div>
			<CarouselLogo />
			<SetMeeting />
			<OtherServices />
		</>
	)
}
// {
// 	posts.map((metadata) => (
// 		<Link to={{
// 			pathname: "/blog/page",
// 			search: `?viewmore=${metadata.title}`,
// 		}} className='blog-anchortag'>
// 			{/* <div className='blog-card-main-div'>
// 				<div className='blog-image-div'><img src={metadata.image} alt="" /></div>
// 				<div className='blog-card-detail-div'>
// 					<h1 className='para-limit1'>{metadata.title}</h1>
// 					<p className='blog-card-author-name'>{metadata.authorName}</p>
// 					<p className='blog-card-creating-date'>{metadata.created_date}</p>
// 				</div>
// 			</div> */}
// 			<div class="blogCard">
// 				<img src={metadata.image} alt="" />
// 				<h2>{metadata.title}</h2>
// 				<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas, officiis.</p>
// 				<div className='blogCardBottomDiv'>
// 					<div class="dots">
// 						<span class="dot active"></span>
// 						<span class="dot"></span>
// 						<span class="dot"></span>
// 					</div>
// 					<button>Detail View  ►</button>
// 				</div>
// 			</div>

// 		</Link>
// 	))
// }