import React, { useState, useCallback, useEffect } from 'react';
import './css/adminPageOfHrm.css';
import './css/leaveForm.css';
import { HashLink as Link } from "react-router-hash-link";
import DateCalender from './DateCalender';
import LeaveFormSection from './LeaveFormSection';
import HrmNavbar from './HrmNavbar';

export default function LeaveForm() {
  const [messageType, setMessageType] = useState('');
  const [messageFromBackend, setMessageFromBackend] = useState('');
  const [btnSection, setButtonSection] = useState('leaveStatus');
  const [formShow, setFormShow] = useState(false)
  const setMessageWithTimeout = (message, typeOfMessage) => {
    setMessageFromBackend(message);
    setMessageType(typeOfMessage);
    setTimeout(() => {
      setMessageFromBackend('');
      setMessageType('');
    }, 3000);
  };

  const handelSectionBtn = (clickType) => {
    if (clickType === 'leaveStatus') {
      setButtonSection('leaveStatus');
    } else if (clickType === 'leaveBalance') {
      setButtonSection('leaveBalance');
    }
  };
  const leaveFormShowHandler = () => {
    setFormShow(!formShow);
  };
  const fetchAllLeaves = useCallback(() => {

    fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getAllLeaveRequest/${localStorage.getItem('contactUserId')}`)
      .then((res) => res.json())
      .then((resData) => {
        // console.log("data", resData)

      })
      .catch((err) => {
        setMessageWithTimeout('Project TaskId not fetch  ', 'error');
        console.error('Error fetching contact:', err);
      });
  }, []);

  const fetchAllLeaveBalance = useCallback(() => {

    fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/getAllLeaveRequest/${localStorage.getItem('contactUserId')}`)
      .then((res) => res.json())
      .then((resData) => {
        // console.log("fetchAllLeaveBalance", resData)

      })
      .catch((err) => {
        setMessageWithTimeout('Project TaskId not fetch  ', 'error');
        console.error('Error fetching contact:', err);
      });
  }, []);
  useEffect(() => {
    fetchAllLeaves()
    fetchAllLeaveBalance()
  }, [])
  const DataForLeaveRequest = {
    "timeCardId": null,
    "success": true,
    "responseJSON": [
      {
        "attributes": {
          "type": "ANT_Leave_Entity__c",
          "url": "/services/data/v61.0/sobjects/ANT_Leave_Entity__c/a0AIm000000UDfNMAW"
        },
        "Id": "a0AIm000000UDfNMAW",
        "Status__c": "Approved",
        "Leave_Type__c": "Earned Leave",
        "Name": "ANT-LE-0001",
        "Employee__c": "003Im000001dpzWIAQ",
        "Start_Date__c": "2024-07-11",
        "End_Date__c": "2024-07-12",
        "Reason_Of_Leave__c": "test resone loremv ukferfguegrfuge  uewy rgfur ug rugr ufjs fef gueyf ef uwekgf wuekgf uewfg uwefewuf u ufgwuegf qwegf  guweyfgwueyfgoqgfweuyuweygweufgewgfowegfwoefgweof woef gewf gwefgweo fgweofgwefgwf yowegfyewf egfewfgew few we fweyuqgwof gewyfgweyugewuyg oywgf ogwefowefew fewgf we"
      },
      {
        "attributes": {
          "type": "ANT_Leave_Entity__c",
          "url": "/services/data/v61.0/sobjects/ANT_Leave_Entity__c/a0AIm000000UDfSMAW"
        },
        "Id": "a0AIm000000UDfSMAW",
        "Status__c": "Reject",
        "Leave_Type__c": "Earned Leave",
        "Name": "ANT-LE-0002",
        "Employee__c": "003Im000001dpzWIAQ",
        "Start_Date__c": "2024-07-13",
        "End_Date__c": "2024-07-15",
        "Reason_Of_Leave__c": "test resone"
      },
      {
        "attributes": {
          "type": "ANT_Leave_Entity__c",
          "url": "/services/data/v61.0/sobjects/ANT_Leave_Entity__c/a0AIm000000UDfSMAW"
        },
        "Id": "a0AIm000000UDfSMAW",
        "Status__c": "Reject",
        "Leave_Type__c": "Earned Leave",
        "Name": "ANT-LE-0002",
        "Employee__c": "003Im000001dpzWIAQ",
        "Start_Date__c": "2024-07-13",
        "End_Date__c": "2024-07-15",
        "Reason_Of_Leave__c": "test resone"
      },
      {
        "attributes": {
          "type": "ANT_Leave_Entity__c",
          "url": "/services/data/v61.0/sobjects/ANT_Leave_Entity__c/a0AIm000000UDfSMAW"
        },
        "Id": "a0AIm000000UDfSMAW",
        "Status__c": "Reject",
        "Leave_Type__c": "Earned Leave",
        "Name": "ANT-LE-0002",
        "Employee__c": "003Im000001dpzWIAQ",
        "Start_Date__c": "2024-07-13",
        "End_Date__c": "2024-07-15",
        "Reason_Of_Leave__c": "test resone"
      },
      {
        "attributes": {
          "type": "ANT_Leave_Entity__c",
          "url": "/services/data/v61.0/sobjects/ANT_Leave_Entity__c/a0AIm000000UDfSMAW"
        },
        "Id": "a0AIm000000UDfSMAW",
        "Status__c": "Reject",
        "Leave_Type__c": "Earned Leave",
        "Name": "ANT-LE-0002",
        "Employee__c": "003Im000001dpzWIAQ",
        "Start_Date__c": "2024-07-13",
        "End_Date__c": "2024-07-15",
        "Reason_Of_Leave__c": "test resone"
      },
      {
        "attributes": {
          "type": "ANT_Leave_Entity__c",
          "url": "/services/data/v61.0/sobjects/ANT_Leave_Entity__c/a0AIm000000UDfSMAW"
        },
        "Id": "a0AIm000000UDfSMAW",
        "Status__c": "Reject",
        "Leave_Type__c": "Earned Leave",
        "Name": "ANT-LE-0002",
        "Employee__c": "003Im000001dpzWIAQ",
        "Start_Date__c": "2024-07-13",
        "End_Date__c": "2024-07-15",
        "Reason_Of_Leave__c": "test resone"
      },
      {
        "attributes": {
          "type": "ANT_Leave_Entity__c",
          "url": "/services/data/v61.0/sobjects/ANT_Leave_Entity__c/a0AIm000000UDfSMAW"
        },
        "Id": "a0AIm000000UDfSMAW",
        "Status__c": "Reject",
        "Leave_Type__c": "Earned Leave",
        "Name": "ANT-LE-0002",
        "Employee__c": "003Im000001dpzWIAQ",
        "Start_Date__c": "2024-07-13",
        "End_Date__c": "2024-07-15",
        "Reason_Of_Leave__c": "test resone"
      },
      {
        "attributes": {
          "type": "ANT_Leave_Entity__c",
          "url": "/services/data/v61.0/sobjects/ANT_Leave_Entity__c/a0AIm000000UDfSMAW"
        },
        "Id": "a0AIm000000UDfSMAW",
        "Status__c": "Reject",
        "Leave_Type__c": "Earned Leave",
        "Name": "ANT-LE-0002",
        "Employee__c": "003Im000001dpzWIAQ",
        "Start_Date__c": "2024-07-13",
        "End_Date__c": "2024-07-15",
        "Reason_Of_Leave__c": "test resone"
      },
      {
        "attributes": {
          "type": "ANT_Leave_Entity__c",
          "url": "/services/data/v61.0/sobjects/ANT_Leave_Entity__c/a0AIm000000UDfSMAW"
        },
        "Id": "a0AIm000000UDfSMAW",
        "Status__c": "Reject",
        "Leave_Type__c": "Earned Leave",
        "Name": "ANT-LE-0002",
        "Employee__c": "003Im000001dpzWIAQ",
        "Start_Date__c": "2024-07-13",
        "End_Date__c": "2024-07-15",
        "Reason_Of_Leave__c": "test resone"
      },
      {
        "attributes": {
          "type": "ANT_Leave_Entity__c",
          "url": "/services/data/v61.0/sobjects/ANT_Leave_Entity__c/a0AIm000000UDfSMAW"
        },
        "Id": "a0AIm000000UDfSMAW",
        "Status__c": "Reject",
        "Leave_Type__c": "Earned Leave",
        "Name": "ANT-LE-0002",
        "Employee__c": "003Im000001dpzWIAQ",
        "Start_Date__c": "2024-07-13",
        "End_Date__c": "2024-07-15",
        "Reason_Of_Leave__c": "test resone"
      },

      {
        "attributes": {
          "type": "ANT_Leave_Entity__c",
          "url": "/services/data/v61.0/sobjects/ANT_Leave_Entity__c/a0AIm000000UDg0MAG"
        },
        "Id": "a0AIm000000UDg0MAG",
        "Status__c": "Pending",
        "Leave_Type__c": "Earned Leave",
        "Name": "ANT-LE-0003",
        "Employee__c": "003Im000001dpzWIAQ",
        "Start_Date__c": "2024-07-16",
        "End_Date__c": "2024-07-17",
        "Reason_Of_Leave__c": "test resone"
      },
      {
        "attributes": {
          "type": "ANT_Leave_Entity__c",
          "url": "/services/data/v61.0/sobjects/ANT_Leave_Entity__c/a0AIm000000UDfSMAW"
        },
        "Id": "a0AIm000000UDfSMAW",
        "Status__c": "Reject",
        "Leave_Type__c": "Earned Leave",
        "Name": "ANT-LE-0002",
        "Employee__c": "003Im000001dpzWIAQ",
        "Start_Date__c": "2024-07-13",
        "End_Date__c": "2024-07-15",
        "Reason_Of_Leave__c": "test resone"
      },
      {
        "attributes": {
          "type": "ANT_Leave_Entity__c",
          "url": "/services/data/v61.0/sobjects/ANT_Leave_Entity__c/a0AIm000000UDfSMAW"
        },
        "Id": "a0AIm000000UDfSMAW",
        "Status__c": "Reject",
        "Leave_Type__c": "Earned Leave",
        "Name": "ANT-LE-0002",
        "Employee__c": "003Im000001dpzWIAQ",
        "Start_Date__c": "2024-07-13",
        "End_Date__c": "2024-07-15",
        "Reason_Of_Leave__c": "test resone"
      },
      {
        "attributes": {
          "type": "ANT_Leave_Entity__c",
          "url": "/services/data/v61.0/sobjects/ANT_Leave_Entity__c/a0AIm000000UDfSMAW"
        },
        "Id": "a0AIm000000UDfSMAW",
        "Status__c": "Reject",
        "Leave_Type__c": "Earned Leave",
        "Name": "ANT-LE-0002",
        "Employee__c": "003Im000001dpzWIAQ",
        "Start_Date__c": "2024-07-13",
        "End_Date__c": "2024-07-15",
        "Reason_Of_Leave__c": "test resone"
      },
      {
        "attributes": {
          "type": "ANT_Leave_Entity__c",
          "url": "/services/data/v61.0/sobjects/ANT_Leave_Entity__c/a0AIm000000UDfSMAW"
        },
        "Id": "a0AIm000000UDfSMAW",
        "Status__c": "Reject",
        "Leave_Type__c": "Earned Leave",
        "Name": "ANT-LE-0002",
        "Employee__c": "003Im000001dpzWIAQ",
        "Start_Date__c": "2024-07-13",
        "End_Date__c": "2024-07-15",
        "Reason_Of_Leave__c": "test resone"
      },
      {
        "attributes": {
          "type": "ANT_Leave_Entity__c",
          "url": "/services/data/v61.0/sobjects/ANT_Leave_Entity__c/a0AIm000000UDfSMAW"
        },
        "Id": "a0AIm000000UDfSMAW",
        "Status__c": "Reject",
        "Leave_Type__c": "Earned Leave",
        "Name": "ANT-LE-0002",
        "Employee__c": "003Im000001dpzWIAQ",
        "Start_Date__c": "2024-07-13",
        "End_Date__c": "2024-07-15",
        "Reason_Of_Leave__c": "test resone"
      },
      {
        "attributes": {
          "type": "ANT_Leave_Entity__c",
          "url": "/services/data/v61.0/sobjects/ANT_Leave_Entity__c/a0AIm000000UDfSMAW"
        },
        "Id": "a0AIm000000UDfSMAW",
        "Status__c": "Reject",
        "Leave_Type__c": "Earned Leave",
        "Name": "ANT-LE-0002",
        "Employee__c": "003Im000001dpzWIAQ",
        "Start_Date__c": "2024-07-13",
        "End_Date__c": "2024-07-15",
        "Reason_Of_Leave__c": "test resone"
      },
      {
        "attributes": {
          "type": "ANT_Leave_Entity__c",
          "url": "/services/data/v61.0/sobjects/ANT_Leave_Entity__c/a0AIm000000UDfSMAW"
        },
        "Id": "a0AIm000000UDfSMAW",
        "Status__c": "Reject",
        "Leave_Type__c": "Earned Leave",
        "Name": "ANT-LE-0002",
        "Employee__c": "003Im000001dpzWIAQ",
        "Start_Date__c": "2024-07-13",
        "End_Date__c": "2024-07-15",
        "Reason_Of_Leave__c": "test resone"
      },
      {
        "attributes": {
          "type": "ANT_Leave_Entity__c",
          "url": "/services/data/v61.0/sobjects/ANT_Leave_Entity__c/a0AIm000000UDfSMAW"
        },
        "Id": "a0AIm000000UDfSMAW",
        "Status__c": "Reject",
        "Leave_Type__c": "Earned Leave",
        "Name": "ANT-LE-0002",
        "Employee__c": "003Im000001dpzWIAQ",
        "Start_Date__c": "2024-07-13",
        "End_Date__c": "2024-07-15",
        "Reason_Of_Leave__c": "test resone"
      },
      {
        "attributes": {
          "type": "ANT_Leave_Entity__c",
          "url": "/services/data/v61.0/sobjects/ANT_Leave_Entity__c/a0AIm000000UDfSMAW"
        },
        "Id": "a0AIm000000UDfSMAW",
        "Status__c": "Reject",
        "Leave_Type__c": "Earned Leave",
        "Name": "ANT-LE-0002",
        "Employee__c": "003Im000001dpzWIAQ",
        "Start_Date__c": "2024-07-13",
        "End_Date__c": "2024-07-15",
        "Reason_Of_Leave__c": "test resone"
      },
      {
        "attributes": {
          "type": "ANT_Leave_Entity__c",
          "url": "/services/data/v61.0/sobjects/ANT_Leave_Entity__c/a0AIm000000UDfSMAW"
        },
        "Id": "a0AIm000000UDfSMAW",
        "Status__c": "Reject",
        "Leave_Type__c": "Earned Leave",
        "Name": "ANT-LE-0002",
        "Employee__c": "003Im000001dpzWIAQ",
        "Start_Date__c": "2024-07-13",
        "End_Date__c": "2024-07-15",
        "Reason_Of_Leave__c": "test resone"
      },

      {
        "attributes": {
          "type": "ANT_Leave_Entity__c",
          "url": "/services/data/v61.0/sobjects/ANT_Leave_Entity__c/a0AIm000000UDg0MAG"
        },
        "Id": "a0AIm000000UDg0MAG",
        "Status__c": "Pending",
        "Leave_Type__c": "Earned Leave",
        "Name": "ANT-LE-0003",
        "Employee__c": "003Im000001dpzWIAQ",
        "Start_Date__c": "2024-07-16",
        "End_Date__c": "2024-07-17",
        "Reason_Of_Leave__c": "test resone"
      },
      {
        "attributes": {
          "type": "ANT_Leave_Entity__c",
          "url": "/services/data/v61.0/sobjects/ANT_Leave_Entity__c/a0AIm000000UDfSMAW"
        },
        "Id": "a0AIm000000UDfSMAW",
        "Status__c": "Reject",
        "Leave_Type__c": "Earned Leave",
        "Name": "ANT-LE-0002",
        "Employee__c": "003Im000001dpzWIAQ",
        "Start_Date__c": "2024-07-13",
        "End_Date__c": "2024-07-15",
        "Reason_Of_Leave__c": "test resone"
      },
    ],
    "leaveEntityId": null,
    "errorMessage": null
  }



  const LeaveBalance = {
    "timeCardId": null,
    "success": true,
    "responseJSON": {
      "remainingSickLeaves": 0.5,
      "remainingEarnedLeaves": 1,
      "remainingCompOffLeaves": 0,
      "lastUsedSickLeaves": 0,
      "lastUsedEarnedLeaves": 0,
      "lastUsedCompOffLeaves": 0,
      "lastUpdatedSickLeaves": 0,
      "lastUpdatedEarnedLeaves": 0,
      "lastUpdatedCompOffLeaves": 0,
      "ContactName": "testing",
      "ContactId": "003Im000002dFjOIAU",
      "AssignedInSickLeaves": 0.5,
      "AssignedInEarnedLeaves": 1
    },
    "leaveEntityId": null,
    "errorMessage": null
  }

  return (
    <div>

      <HrmNavbar />
      {formShow === false &&
        (
          <div className='container'>
            <div className='leaveFormBtnDiv'>
              <button className='leaveFormBtnSection' onClick={() => handelSectionBtn('leaveStatus')}>Leave Status</button>
              <button className='leaveFormBtnSection' onClick={() => handelSectionBtn('leaveBalance')}>Leave Balance</button>
            </div>
          </div>
        )}
      {formShow === false &&
        (<div className='container'>
          <div className='leavebtnShowSection' >
            <button className='approvedBtn' onClick={leaveFormShowHandler}>Leave Form</button>
          </div>
        </div>)
      }
      {formShow === false && btnSection === 'leaveBalance' && (
        <div className='container'>

          <div className="leave-tableBalance" style={{ color: "black" }}>
            <div className='gridLeaveBalance' style={{ backgroundColor: "#f2f2f2" }}>
              <p>Leaves</p>
              <p>Earned Leaves</p>
              <p>Sick Leaves</p>
              <p>Comp Off</p>
            </div>

            <div className=''>
              <div className='gridLeaveBalance'>
                <p>Last Updated</p>
                <p>{LeaveBalance?.responseJSON?.lastUpdatedEarnedLeaves || 0}</p>
                <p>{LeaveBalance?.responseJSON?.lastUpdatedSickLeaves || 0}</p>
                <p>{LeaveBalance?.responseJSON?.lastUpdatedCompOffLeaves || 0}</p>
              </div>
              <div className='gridLeaveBalance'>
                <p>Assigned in June</p>
                <p>{LeaveBalance?.responseJSON?.AssignedInEarnedLeaves || 0}</p>
                <p>{LeaveBalance?.responseJSON?.AssignedInSickLeaves || 0}</p>
                <p>{LeaveBalance?.responseJSON?.AssignedInCompOffLeaves || 0}</p>
              </div>
              <div className='gridLeaveBalance'>
                <p>Used after Last Updated Sent</p>
                <p>{LeaveBalance?.responseJSON?.lastUsedEarnedLeaves || 0}</p>
                <p>{LeaveBalance?.responseJSON?.lastUsedSickLeaves || 0}</p>
                <p>{LeaveBalance?.responseJSON?.lastUsedCompOffLeaves || 0}</p>
              </div>
              <div className='gridLeaveBalance' style={{ backgroundColor: "#f2f2f2" }}>
                <p>Remaining</p>
                <p>{LeaveBalance?.responseJSON?.remainingEarnedLeaves || 0}</p>
                <p>{LeaveBalance?.responseJSON?.remainingSickLeaves || 0}</p>
                <p>{LeaveBalance?.responseJSON?.remainingCompOffLeaves || 0}</p>
              </div>
            </div>
          </div>

        </div>
      )}

      {formShow === false && btnSection === 'leaveStatus' && (
        <div className='container'>
          <table className="leave-table ">
            <thead>
              <tr>
                <th className="leave-type">Leave Type</th>
                <th className="start-date">Start Date</th>
                <th className="end-date">End Date</th>
                <th className="reason">Reason of Leave</th>
                <th className="status">Status</th>
              </tr>
            </thead>
            <tbody className='scrollableClassTable'>
              {/* <tr>
                <td className="leave-type">Earned Leave</td>
                <td className="start-date">2020-05-30</td>
                <td className="end-date">2020-05-30</td>
                <td className="reason">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum quasi aspernatur in eligendi dolores praesentium quidem dicta alias architecto nemo.</td>
                <td className="status approved">Approved</td>
              </tr>
              <tr>
                <td className="leave-type">Earned Leave</td>
                <td className="start-date">2020-05-30</td>
                <td className="end-date">2020-05-30</td>
                <td className="reason">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum quasi aspernatur in eligendi dolores praesentium quidem dicta alias architecto nemo.</td>
                <td className="status not-approved">Not Approved</td>
              </tr> */}
              {
                DataForLeaveRequest.responseJSON && DataForLeaveRequest.responseJSON.map((row, index) => (
                  <tr key={index} style={{ color: "black" }}>
                    <td className="leave-type">{row.Leave_Type__c || ''}</td>
                    <td className="start-date">{row.Start_Date__c || ''}</td>
                    <td className="end-date">{row.End_Date__c || ''}</td>
                    <td className="reason">{row.Reason_Of_Leave__c || ''}</td>
                    <td className={`status ${row.Status__c?.toLowerCase() || 'unknown'}`}>{row.Status__c || ''}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>

      )}
      {
        formShow && (<LeaveFormSection leaveFormShowHandler={leaveFormShowHandler} />)
      }
    </div>
  );
}
