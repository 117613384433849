import React, { Component } from 'react';
import './Feed.css';
import TextEditorForAdminPanel from './TextEditorForAdminPanel.js';

class Feed extends Component {
  render() {
    return (
      <div className='right-main-div'>
      
      </div>
    );
  }
}

export default Feed;

