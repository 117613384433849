import React from 'react'
import Service from "./Components/Service";
import Banner from "./Components/Banner";
import Work from "./Components/Work";
import Testimonial from "./Components/Testimonial";
import Contact from "./pages/Contact"
import CarouselLogo from "./Components/CarouselLogo";
/*
*********************************************************************************************************
*  @Parent Component           :    main page 
*  @React Component Name       :    MainPage
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    05-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       Home page
********************************************************************************************************
*/
export default function MainPage(props) {

	return (
		<div style={{ scrollBehavior: "smooth" }}>
			{localStorage.getItem('userId') != null ? (
				<>
				</>
			) : (<>
			<div style={{height:'100vh'}}>
			<Banner />
			<CarouselLogo />
			</div>
				<Service />
				<Work />
				<Testimonial />
				<Contact />
			</>
			)}
		</div>
	)
}
