import React, { Component } from 'react'
import Auth from '../Auth/Auth';
import TextEditorForAdminPanel from './TextEditorForAdminPanel';

/*
*********************************************************************************************************
*  @Parent Component           :    Admin Panel
*  @React Component Name       :    Leets
*  @Author                     :    <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
*  @Company                    :    Antrazal
*  @Date                       :    04-07-2024
* 
*******************************************************************************************************
* Developer                                             TITLE
* <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)       leads Admin page 
********************************************************************************************************
*/

export default class Leets extends Component {

    /*
    *********************************************************************************************************
    *   @React Component Name       :       Leets
    *   @Method                     :       constructor
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       initialized variables
    *********************************************************************************************************
    */

    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            postsLoading: false,
            editorContent: '',
            initialContent:``
        };
    };

    /*
    *********************************************************************************************************
    *   @React Component Name       :       Leets
    *   @Method                     :       componentDidMount
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       call the api when on changes 
    *********************************************************************************************************
    */

    componentDidMount() {
        this.fetchPosts();
    }

    handleContentChange = (content) => {
        this.setState({ editorContent: content });
    };
    /*
    *********************************************************************************************************
    *   @React Component Name       :       Leets
    *   @Method                     :       fetchPosts
    *   @Author                     :       <Bajrang Mahawar>(bajrang.mahawar@antrazal.com)
    *   @Company                    :       Antrazal
    *   @Description                :       fetch the leads data from the backend 
    *********************************************************************************************************
    */

    fetchPosts = () => {
        this.setState({ postsLoading: true });
        fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/Leets`)
            .then((res) => res.json())
            .then((resData) => {
                this.setState({ posts: resData, postsLoading: false });
            })
            .catch((err) => {
                console.error('Error fetching blog posts:', err);
                this.setState({ postsLoading: false });
            });
    };

    render() {
        const { editorContent,initialContent } = this.state
        return (
            <Auth>
                {this.state.posts.length <= 0 && !this.state.postsLoading ? (
                    <p style={{ textAlign: 'center' }}>No posts found.</p>
                ) : null}
                <table style={{ width: "100%", borderCollapse: "" }}>
                    <tr style={{ borderBottom: "1px solid #ccc" }}>
                        <th style={{ textAlign: "center" }}>Email</th>
                        <th style={{ textAlign: "center" }}>Phone number</th>
                    </tr>
                    {this.state.posts.map((post) => (
                        <tr style={{ borderBottom: "1px solid #ccc" }}>
                            <td style={{ padding: "0.5rem 0", border: "1px solid #ccc" }}>{post.email}</td>
                            <td style={{ padding: "0.5rem 0", border: "1px solid #ccc" }}>{
                                post.phonenumber.length === 0 ? "Null" : post.phonenumber
                            }</td>
                        </tr>
                    ))}
                </table>
                <div style={{ margin: "1rem 0" }}>
                    <TextEditorForAdminPanel onContentChange={this.handleContentChange} initialContent={initialContent} ></TextEditorForAdminPanel>
                </div>

                <div dangerouslySetInnerHTML={{ __html: editorContent }} />
            </Auth>
        )
    }
}

