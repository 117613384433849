import React, { useState,useEffect } from 'react';
import './css/adminPageOfHrm.css';
import './css/leaveForm.css';
import DatePickerForLeaveForm from './DatePickerForLeaveForm';

export default function LeaveFormSection(props) {
    const formatDate = (date) => {
        if (!(date instanceof Date) || isNaN(date)) {
            return '';
        }
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();
        return `${year}-${month}-${day}`;
    };

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [leaveType, setLeaveType] = useState('');
    const [reason, setReason] = useState('');

    const startDateFun = (date) => {
        if (date instanceof Date && !isNaN(date)) {
            setStartDate(date);
        }
        if(leaveType=="Half Day "){
            setEndDate(date)
        }
    };

    const endDateFun = (date) => {
        if (date instanceof Date && !isNaN(date)) {
            setEndDate(date);
        }
    };
    useEffect(() => {
            }, [startDate,endDate,leaveType,reason])


    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     if (reason.trim() === '') {
    //         alert('Reason for leave cannot be empty or just spaces.');
    //         return;
    //     }
    //     const formData = {
    //         leaveType,
    //         startDate: formatDate(startDate),
    //         endDate: formatDate(endDate),
    //         reason,
    //     };
    //     try {
    //         const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/CreateTimeCard`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify(newTimeCardEntry)
    //         });
    //         if (!response.ok) {
    //             throw new Error('Network response was not ok');
    //         }
          
           
    //         // setMessageWithTimeout("Time card Updated successfully.", 'success');
    //     } catch (error) {
    //         console.error('Error sending data to the API:', error);
    //     }
    //     const jsonData = JSON.stringify(formData);
    //     console.log("json", jsonData);
    //     setStartDate(new Date());
    //     setEndDate(new Date());
    //     setLeaveType('');
    //     setReason('');
    // };
    const handleSubmit = async (event) => {
        event.preventDefault();
    
        if (reason.trim() === '') {
            alert('Reason for leave cannot be empty or just spaces.');
            return;
        }
    
        const formData = {
            leaveType,
            startDate: formatDate(startDate),
            endDate: formatDate(endDate),
            reason,
        };
    
        try {
            const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/CreateLeaveForm/${localStorage.getItem('contactUserId')}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
        } catch (error) {
            console.error('Error sending data to the API:', error);
        }
    
        const jsonData = JSON.stringify(formData);
         
        setStartDate(new Date());
        setEndDate(new Date());
        setLeaveType('');
        setReason('');
    };
    

    const handleLeaveTypeChange = (e) => {
        const selectedLeaveType = e.target.value;
        setLeaveType(selectedLeaveType);

        if (selectedLeaveType === 'Half Day') {
       
            setEndDate(startDate);
        }
    };

    return (
        <div className='leaveFormMainDiv'>
            <div className='containerSelf leaveFormMainContainer'>
                <div className='closeBtn-divLeaveForm' onClick={props.leaveFormShowHandler}>
                    <svg viewBox="0 0 24 24" fill="none"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Menu / Close_MD"> <path id="Vector" d="M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>
                </div>
                <form className="container" typeof='submit' onSubmit={handleSubmit}>
                    <div className="leave-options">
                        <p>Please select your leave type:</p>
                        <input type="radio" id="earned_leave" name="leave_type" value="Earned Leave" onChange={handleLeaveTypeChange} checked={leaveType === 'Earned Leave'} required />

                        <label htmlFor="earned_leave">Earned Leave</label><br />
                        <input type="radio" id="sick" name="leave_type" value="Sick Leave" onChange={handleLeaveTypeChange} checked={leaveType === 'Sick Leave'} />

                        <label htmlFor="sick">Sick</label><br />
                        <input type="radio" id="elective_leave" name="leave_type" value="Elective Leave" onChange={handleLeaveTypeChange} checked={leaveType === 'Elective Leave'} />
                        
                        <label htmlFor="elective_leave">Elective Leave</label><br />
                        <input type="radio" id="half_day" name="leave_type" value="Half Day" onChange={handleLeaveTypeChange} checked={leaveType === 'Half Day'} />
                        <label htmlFor="half_day">Half Day</label>
                    </div>
                    <div className="datePickerDiv">
                        <div>
                            <p>Start Date</p>
                            <DatePickerForLeaveForm 
                                timeDate={startDateFun}  
                                selectedDate={startDate} 
                                setSelectedDateFun={startDateFun} 
                            />
                        </div>
                        <div>
                            <p>End Date</p>
                            <DatePickerForLeaveForm 
                                timeDate={endDateFun} 
                                selectedDate={endDate} 
                                setSelectedDateFun={endDateFun} 
                            />
                        </div>
                    </div>
                    <div className="reason-textarea">
                        <label htmlFor="reason">Reason for leave</label>
                        <textarea id="reason" name="reason" placeholder="Enter here" required onChange={(e) => setReason(e.target.value)} value={reason}></textarea>
                    </div>
                    <div> 
                        <button className='updateStatus-btn' type="submit">Save</button>
                    </div>
                </form>
                <p>{formatDate(startDate)}</p>
                <p>{formatDate(endDate)}</p>
                <p>{leaveType}</p>
            </div>
        </div>
    );
}
