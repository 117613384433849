
import React, { useState, useEffect } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Mention from '@tiptap/extension-mention';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import Image from '@tiptap/extension-image';
import TextStyle from '@tiptap/extension-text-style';
import './css/texteditor.css';
import 'tippy.js/dist/tippy.css';
import Color from '@tiptap/extension-color';
import Link from '@tiptap/extension-link';
const TextEditor = ({ allUserOption, taskId, updateProjectData, SetLoader, setMessageWithTimeout, setCommentLoadeBegin }) => {

    const [htmlContent, setHtmlContent] = useState('');

    const [selectedColor, setSelectedColor] = useState('black');
    const [isPickerVisible, setIsPickerVisible] = useState(false);
    const [smallLoading, setSmallLoading] = useState(false);
    const [urlToReplace, setUrlToReplace] = useState('');
    const [replacementText, setReplacementText] = useState('');
    const [toggleShowHyperLinkDrop, setToggleShowHyperLinkDrop] = useState(false)
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {

    }, [htmlContent]);

    function generateRandomId() {
        const prefix = 'a06J1';
        const length = 13;
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        let randomPart = '';
        for (let i = 0; i < length; i++) {
            randomPart += characters.charAt(Math.floor(Math.random() * characters.length));
        }

        return prefix + randomPart;
    }
    // const editor = useEditor({
    //     extensions: [
    //         StarterKit,
    //         TextStyle.configure({
    //             types: ['textStyle'],
    //         }),
    //         Color,
    //         Image,
    //         Link,
    //         Mention.configure({
    //             HTMLAttributes: {
    //                 class: 'mention',
    //             },
    //             suggestion: {
    //                 char: '@',
    //                 allowSpaces: false,
    //                 items: ({ query }) => {
    //                     return allUserOption.filter((item) =>
    //                         item.label.toLowerCase().includes(query.toLowerCase())
    //                     );
    //                 },
    //                 render: () => {
    //                     let component;
    //                     let popup;
    //                     let selectedIndex = allUserOption.length > 0 ? allUserOption[0].value : null;
    //                     let currentSuggestionItems = [];

    //                     const selectItem = (id) => {
    //                         const itemsElements = component.querySelectorAll('.suggestion-item');
    //                         itemsElements.forEach((itemElement) => {
    //                             if (itemElement.getAttribute('data-id') === id) {
    //                                 itemElement.classList.add('active');
    //                                 itemElement.scrollIntoView({ block: 'nearest' });
    //                             } else {
    //                                 itemElement.classList.remove('active');
    //                             }
    //                         });
    //                     };
    //                     const executeCommand = (id, range) => {
    //                         const item = allUserOption.find((item) => item.value === id);
    //                         if (item) {
    //                             editor
    //                                 .chain()
    //                                 .focus()
    //                                 .deleteRange(range)
    //                                 .insertContent({
    //                                     type: 'mention',
    //                                     attrs: { id: item.value, label: item.label },
    //                                 })
    //                                 .run();
    //                         }
    //                         if (popup) {
    //                             popup[0].hide();
    //                         }
    //                     };

    //                     return {

    //                         onStart: ({ items: suggestionItems, clientRect: suggestionClientRect, range }) => {
    //                             currentSuggestionItems = suggestionItems;
    //                             component = document.createElement('div');
    //                             component.classList.add('suggestion');

    //                             suggestionItems.forEach((item) => {
    //                                 const itemElement = document.createElement('div');
    //                                 itemElement.classList.add('suggestion-item');
    //                                 const container = document.createElement('div');
    //                                 container.classList.add('suggestion-item-container');
    //                                 const firstCharElement = document.createElement('div');
    //                                 firstCharElement.classList.add('suggestion-item-char');
    //                                 firstCharElement.textContent = item.label ? item.label.charAt(0) : "";
    //                                 const fullNameElement = document.createElement('p');
    //                                 fullNameElement.classList.add('suggestion-item-name');
    //                                 fullNameElement.textContent = item.label ? item.label : "";
    //                                 container.appendChild(firstCharElement);
    //                                 container.appendChild(fullNameElement);
    //                                 itemElement.appendChild(container);
    //                                 itemElement.setAttribute('data-id', item.value);
    //                                 itemElement.addEventListener('click', () => {
    //                                     executeCommand(item.value, range);
    //                                 });

    //                                 component.appendChild(itemElement);
    //                             });

    //                             selectItem(selectedIndex);
    //                             popup = tippy('body', {
    //                                 getReferenceClientRect: suggestionClientRect,
    //                                 appendTo: document.body,
    //                                 content: component,
    //                                 showOnCreate: true,
    //                                 interactive: true,
    //                             });
    //                         },

    //                         onUpdate: ({ items: suggestionItems, clientRect: suggestionClientRect, range }) => {
    //                             currentSuggestionItems = suggestionItems;
    //                             while (component.firstChild) {
    //                                 component.firstChild.remove();
    //                             }
    //                             suggestionItems.forEach((item) => {
    //                                 const itemElement = document.createElement('div');
    //                                 itemElement.classList.add('suggestion-item');
    //                                 const container = document.createElement('div');
    //                                 container.classList.add('suggestion-item-container');
    //                                 const firstCharElement = document.createElement('div');
    //                                 firstCharElement.classList.add('suggestion-item-char');
    //                                 firstCharElement.textContent = item.label ? item.label.charAt(0) : "";
    //                                 const fullNameElement = document.createElement('p');
    //                                 fullNameElement.classList.add('suggestion-item-name');
    //                                 fullNameElement.textContent = item.label ? item.label : "";
    //                                 container.appendChild(firstCharElement);
    //                                 container.appendChild(fullNameElement);
    //                                 itemElement.appendChild(container);
    //                                 itemElement.setAttribute('data-id', item.value);
    //                                 itemElement.addEventListener('click', () => {
    //                                     executeCommand(item.value, range);
    //                                 });
    //                                 component.appendChild(itemElement);
    //                             });

    //                             selectItem(selectedIndex);

    //                             if (popup) {
    //                                 popup[0].setProps({
    //                                     getReferenceClientRect: suggestionClientRect,
    //                                 });
    //                             }
    //                         },
    //                         onKeyDown: ({ event, range }) => {
    //                             const currentIndex = currentSuggestionItems.findIndex(item => item.value === selectedIndex);
    //                             if (event.key === 'ArrowDown') {
    //                                 const nextIndex = (currentIndex + 1) % currentSuggestionItems.length;
    //                                 selectedIndex = currentSuggestionItems[nextIndex].value;
    //                                 selectItem(selectedIndex);
    //                                 return true;
    //                             }

    //                             if (event.key === 'ArrowUp') {
    //                                 const prevIndex = (currentIndex - 1 + currentSuggestionItems.length) % currentSuggestionItems.length;
    //                                 selectedIndex = currentSuggestionItems[prevIndex].value;
    //                                 selectItem(selectedIndex);
    //                                 return true;
    //                             }

    //                             if (event.key === 'Enter') {
    //                                 console.log("selectedIndex",selectedIndex)
    //                                 console.log("range",range)
    //                                 executeCommand(selectedIndex, range);
    //                                 return true;
    //                             }

    //                             return false;
    //                         },
    //                         onExit: () => {
    //                             if (popup) {
    //                                 popup[0].destroy();
    //                             }
    //                         },
    //                     };
    //                 },
    //             },
    //         }),
    //     ],
    //     content: '<p></p>',
    //     editorProps: {
    //         handleDOMEvents: {
    //             paste: async (view, event) => {
    //                 const clipboardData = event.clipboardData;
    //                 if (!clipboardData) {
    //                     console.error("No clipboard data available");
    //                     return;
    //                 }
        
    //                 const pastedItems = clipboardData.items;
    //                 if (!pastedItems) {
    //                     console.error("No items in clipboard data");
    //                     return;
    //                 }
        
    //                 for (let i = 0; i < pastedItems.length; i++) {
    //                     const item = pastedItems[i];
    //                     if (item.type.indexOf('image') !== -1) {
    //                         const file = item.getAsFile();
    //                         if (file) {
    //                             event.preventDefault();
    //                             const reader = new FileReader();
    //                             reader.onload = async function (e) {
    //                                 const imageDataUrl = e.target.result;
    //                                 await postInputChangeHandler(null, null, [file]);
    //                                 const imgNode = view.state.schema.nodes.image.create({
    //                                     src: imageDataUrl,
    //                                 });
    //                                 const transaction = view.state.tr.replaceSelectionWith(imgNode);
    //                                 view.dispatch(transaction);
    //                             };
    //                             reader.readAsDataURL(file);
        
    //                             return true;
    //                         } else {
    //                             console.error("Failed to get image file");
    //                         }
    //                     }
    //                 }
    //                 return false;
    //             }
    //         },
    //     },
        
    // });
    const editor = useEditor({
        extensions: [
          StarterKit,
          TextStyle.configure({
            types: ['textStyle'],
          }),
          Color,
          Image,
          Link,
          Mention.configure({
            HTMLAttributes: {
              class: 'mention',
            },
            suggestion: {
              char: '@',
              allowSpaces: false,
              items: ({ query }) => {
                return allUserOption.filter((item) =>
                  item.label.toLowerCase().includes(query.toLowerCase())
                );
              },
              render: () => {
                let component;
                let popup;
                let selectedIndex = 0; // Start with the first item selected by default
                let currentSuggestionItems = [];
      
                const selectItem = (index) => {
                  const itemsElements = component.querySelectorAll('.suggestion-item');
                  itemsElements.forEach((itemElement, idx) => {
                    if (idx === index) {
                      itemElement.classList.add('active');
                      itemElement.scrollIntoView({ block: 'nearest' });
                    } else {
                      itemElement.classList.remove('active');
                    }
                  });
                };
      
                const executeCommand = (index, range) => {
                  const item = currentSuggestionItems[index];
                  if (item) {
                    editor
                      .chain()
                      .focus()
                      .deleteRange(range)
                      .insertContent({
                        type: 'mention',
                        attrs: { id: item.value, label: item.label },
                      })
                      .run();
                  }
                  if (popup) {
                    popup[0].hide();
                  }
                };
      
                return {
                  onStart: ({ items: suggestionItems, clientRect: suggestionClientRect, range }) => {
                    currentSuggestionItems = suggestionItems;
                    component = document.createElement('div');
                    component.classList.add('suggestion');
      
                    suggestionItems.forEach((item, idx) => {
                      const itemElement = document.createElement('div');
                      itemElement.classList.add('suggestion-item');
                      if (idx === selectedIndex) {
                        itemElement.classList.add('active');
                      }
                      const container = document.createElement('div');
                      container.classList.add('suggestion-item-container');
                      const firstCharElement = document.createElement('div');
                      firstCharElement.classList.add('suggestion-item-char');
                      firstCharElement.textContent = item.label ? item.label.charAt(0) : '';
                      const fullNameElement = document.createElement('p');
                      fullNameElement.classList.add('suggestion-item-name');
                      fullNameElement.textContent = item.label ? item.label : '';
                      container.appendChild(firstCharElement);
                      container.appendChild(fullNameElement);
                      itemElement.appendChild(container);
                      itemElement.setAttribute('data-id', item.value);
                      itemElement.addEventListener('click', () => {
                        executeCommand(idx, range);
                      });
      
                      component.appendChild(itemElement);
                    });
      
                    selectItem(selectedIndex);
      
                    popup = tippy('body', {
                      getReferenceClientRect: suggestionClientRect,
                      appendTo: document.body,
                      content: component,
                      showOnCreate: true,
                      interactive: true,
                    });
                  },
      
                  onUpdate: ({ items: suggestionItems, clientRect: suggestionClientRect, range }) => {
                    currentSuggestionItems = suggestionItems;
                    while (component.firstChild) {
                      component.firstChild.remove();
                    }
      
                    suggestionItems.forEach((item, idx) => {
                      const itemElement = document.createElement('div');
                      itemElement.classList.add('suggestion-item');
                      if (idx === selectedIndex) {
                        itemElement.classList.add('active');
                      }
                      const container = document.createElement('div');
                      container.classList.add('suggestion-item-container');
                      const firstCharElement = document.createElement('div');
                      firstCharElement.classList.add('suggestion-item-char');
                      firstCharElement.textContent = item.label ? item.label.charAt(0) : '';
                      const fullNameElement = document.createElement('p');
                      fullNameElement.classList.add('suggestion-item-name');
                      fullNameElement.textContent = item.label ? item.label : '';
                      container.appendChild(firstCharElement);
                      container.appendChild(fullNameElement);
                      itemElement.appendChild(container);
                      itemElement.setAttribute('data-id', item.value);
                      itemElement.addEventListener('click', () => {
                        executeCommand(idx, range);
                      });
                      component.appendChild(itemElement);
                    });
      
                    selectItem(selectedIndex);
      
                    if (popup) {
                      popup[0].setProps({
                        getReferenceClientRect: suggestionClientRect,
                      });
                    }
                  },
      
                  onKeyDown: ({ event, range }) => {
                    if (event.key === 'ArrowDown') {
                      selectedIndex = (selectedIndex + 1) % currentSuggestionItems.length;
                      selectItem(selectedIndex);
                      return true;
                    }
      
                    if (event.key === 'ArrowUp') {
                      selectedIndex = (selectedIndex - 1 + currentSuggestionItems.length) % currentSuggestionItems.length;
                      selectItem(selectedIndex);
                      return true;
                    }
      
                    if (event.key === 'Enter') {
                      executeCommand(selectedIndex, range);
                      return true;
                    }
      
                    return false;
                  },
      
                  onExit: () => {
                    if (popup) {
                      popup[0].destroy();
                    }
                  },
                };
              },
            },
          }),
        ],
        content: '<p></p>',
        editorProps: {
          handleDOMEvents: {
            paste: async (view, event) => {
              const clipboardData = event.clipboardData;
              if (!clipboardData) {
                console.error("No clipboard data available");
                return;
              }
      
              const pastedItems = clipboardData.items;
              if (!pastedItems) {
                console.error("No items in clipboard data");
                return;
              }
      
              for (let i = 0; i < pastedItems.length; i++) {
                const item = pastedItems[i];
                if (item.type.indexOf('image') !== -1) {
                  const file = item.getAsFile();
                  if (file) {
                    event.preventDefault();
                    const reader = new FileReader();
                    reader.onload = async function (e) {
                      const imageDataUrl = e.target.result;
                      await postInputChangeHandler(null, null, [file]);
                      const imgNode = view.state.schema.nodes.image.create({
                        src: imageDataUrl,
                      });
                      const transaction = view.state.tr.replaceSelectionWith(imgNode);
                      view.dispatch(transaction);
                    };
                    reader.readAsDataURL(file);
      
                    return true;
                  } else {
                    console.error("Failed to get image file");
                  }
                }
              }
              return false;
            },
          },
        },
      });
      
    if (!editor) {
        return null;
    }

    function hasValidContent(node) {
        if (!node || typeof node !== 'object') return false;
        if (node.type === 'text' && node.text && node.text.trim() !== '') {
            return true;
        }
        if (node.type === 'mention' && node.attrs && node.attrs.label) {
            return true;
        }
        if (node.type === 'image') {
            return true;
        }
        if (node.content) {
            return node.content.some(childNode => hasValidContent(childNode));
        }

        return false;
    }

    const handleSubmitComment = async () => {
        try {
            setSmallLoading(true);
            const content = editor.getHTML()
            const jsonContent = editor.getJSON();
            const isValidContent = jsonContent.content.some(node => hasValidContent(node));
            // console.log('isvalid content: ' + isValidContent)
            // console.log("editor.getHTML()", editor.getHTML())
            if (!isValidContent || content.trim() === '<p></p>') {
                setSmallLoading(false);
                setMessageWithTimeout('Please enter a valid comment', 'error');
                return;
            }
            else {
                const ids = Array.from(content.matchAll(/data-id="([^"]+)"/g), m => m[1]);
                const uniqueIds = [...new Set(ids)];
                const mentionId = uniqueIds.join(',');
                const rndNumber = generateRandomId();
                updateProjectData({ content: content, Id: rndNumber })
                setCommentLoadeBegin({ Id: rndNumber, loading: true })

                const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/internalUser/CreateCommentById/${taskId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ Comment__c: editor.getHTML(), Commented_By__c: localStorage.getItem('contactUserId'), Mentioned__c: mentionId }),

                });
                if (!response.ok) {
                    setSmallLoading(false);
                    setMessageWithTimeout('Failed to submit comment2', 'error');
                    editor.commands.clearContent();
                    throw new Error('Failed to submit comment');
                }

                editor.commands.clearContent();
                setSmallLoading(false)
                setCommentLoadeBegin({ Id: null, loading: false })
            }
        } catch (error) {
            setSmallLoading(false);
            console.log(error)
            editor.commands.clearContent();
            setMessageWithTimeout('Failed to submit comment', 'error');

        }
    };
    const colors = [
        { name: 'Red', value: '#FF0000' },
        { name: 'Green', value: '#00FF00' },
        { name: 'Blue', value: '#0000FF' },
        { name: 'Yellow', value: '#FFFF00' },
        { name: 'Magenta', value: '#FF00FF' },
        { name: 'Cyan', value: '#00FFFF' },
        { name: 'Black', value: '#000000' },
        { name: 'White', value: '#FFFFFF' },
        { name: 'Orange', value: '#FFA500' },
        { name: 'Purple', value: '#800080' },
        { name: 'Dark Green', value: '#008000' },
        { name: 'Pink', value: '#FFC0CB' },
        { name: 'Brown', value: '#A52A2A' },
        { name: 'Maroon', value: '#800000' },
        { name: 'Olive', value: '#808000' },
        { name: 'Navy', value: '#000080' },
        { name: 'Gray', value: '#808080' },
        { name: 'Gold', value: '#FFD700' },
        { name: 'Crimson', value: '#DC143C' },
        { name: 'Turquoise', value: '#40E0D0' },
        { name: 'Indigo', value: '#4B0082' },
        { name: 'Violet', value: '#EE82EE' },
        { name: 'Lime', value: '#00FF00' },
        { name: 'Teal', value: '#008080' },
        { name: 'Lavender', value: '#E6E6FA' },
        { name: 'Beige', value: '#F5F5DC' },
        { name: 'Mint', value: '#98FF98' },
        { name: 'Coral', value: '#FF7F50' },
        { name: 'Salmon', value: '#FA8072' },
        { name: 'Chocolate', value: '#D2691E' },
        { name: 'Khaki', value: '#F0E68C' },
        { name: 'Plum', value: '#DDA0DD' },
        { name: 'Orchid', value: '#DA70D6' },
        { name: 'Azure', value: '#F0FFFF' },
        { name: 'Ivory', value: '#FFFFF0' },
        { name: 'Chartreuse', value: '#7FFF00' },
        { name: 'Cerulean', value: '#007BA7' },
        { name: 'Fuchsia', value: '#FF00FF' },
        { name: 'Emerald', value: '#50C878' },
        { name: 'Amber', value: '#FFBF00' },
    ];

    const handleColorChange = (color) => {
        editor.chain().focus().setColor(color).run();
    };
    const togglePickerVisibility = () => {
        setIsPickerVisible(!isPickerVisible);
    };
    const handleColorClick = (color) => {
        setSelectedColor(color.value);
        handleColorChange(color.value);
    };

    const postInputChangeHandler = async (input, value, files) => {
    //  console.log('file fun',files)
        if (files && files.length > 0) {
            const formData = new FormData();
            formData.append('file', files[0]);
            // console.log(formData)
            try {
                setLoading(true)
                const response = await fetch(`${process.env.REACT_APP_PRODUCTION_API_URL}/backend/api/v1.0/AntrazalSolutionsPrivatelimited/upload`, {
                    method: 'POST',
                    body: formData,
                });
                if (response.ok) {
                    const { url } = await response.json();
                    editor.chain().focus().setImage({ src: url, style: "width: 100%; height: auto;", class: "editorImageWidthTextEditor" }).run();
                    const allImages = document.querySelectorAll('.ProseMirror img');
                    const lastImage = allImages[allImages.length - 1];
                    if (lastImage) {
                        lastImage.classList.add('editorImageWidthTextEditor');
                        lastImage.style.width = "100%";
                        lastImage.style.height = "auto";
                    }
                    setLoading(false)
                } else {
                    setLoading(false)
                    throw new Error('Failed to upload image');
                }
            } catch (error) {
                console.error('Error uploading image:', error);
                
            } finally {
                setSmallLoading(false);
            }
        }
    };

    const handleImageUpload = (event) => {
        const files = event.target.files;
        postInputChangeHandler('photo', '', files);
    };

    const handleReplaceLinks = () => {
        const content = editor.getHTML();
        if (!replacementText?.trim() || !urlToReplace?.trim()) {
            toggleHyperLinkDrop()
            return;
        }
        // console.log('Original content:', content);
        const escapedUrlToReplace = urlToReplace.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const newContent = content.replace(
            new RegExp(`<a href="${escapedUrlToReplace}"[^>]*>[^<]*<\/a>`, 'g'),
            replacementText
        );
        // console.log('Content with replacements:', newContent);
        const url = urlToReplace;
        const linkText = replacementText;
        const linkHTML = `<a target="_blank" rel="noopener noreferrer nofollow" href="${url}">${linkText}</a><span> </span>`;
        editor.chain()
            .focus()
            .deleteRange({ from: editor.state.selection.from, to: editor.state.selection.to })
            .insertContentAt(editor.state.selection.from, linkHTML)
            .run();
        // console.log("text", linkHTML)
        toggleHyperLinkDrop()
        setReplacementText('');
        setUrlToReplace('');
    };
    const toggleHyperLinkDrop = () => {
        setToggleShowHyperLinkDrop(!toggleShowHyperLinkDrop)
    }
    return (
        <div >
            <div className='textEditorDiv'>
                <div className="editor-toolbar">
                    <div
                        className={`editor-button ${editor.isActive('bold') ? 'active' : ''}`}
                        onClick={() => editor.chain().focus().toggleBold().run()}
                        data-tippy-content="Bold"
                    >
                        <svg fill="#000000" version="1.1" id="Capa_1" viewBox="0 0 202 202" stroke="#000000">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path d="M148.004,94.812c18.332-8.126,28.671-23.362,28.671-42.752c0-17.261-6.954-31.206-20.11-40.328C145.653,4.166,130.438,0,113.721,0H16.957v34h17v134h-17v34h90.905c14.819,0,35.992-2.245,52.705-12.94c16.241-10.393,24.476-26.161,24.476-46.868C185.043,118.342,171.057,100.763,148.004,94.812z M103.12,80H73.957V34h26.096c25.961,0,36.551,6.34,36.551,21.884C136.604,75.816,118.396,80,103.12,80z M73.957,115h30.838c28.537,0,40.177,7.436,40.177,25.663c0,18.14-13.987,27.337-41.572,27.337H73.957V115z"></path>
                            </g>
                        </svg>
                    </div>
                    <div
                        className={`editor-button ${editor.isActive('italic') ? 'active' : ''}`}
                        onClick={() => editor.chain().focus().toggleItalic().run()}
                        data-tippy-content="Italic"
                    >
                        <svg viewBox="0 0 20 20" fill="none">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 3L8 17m4-14H8m4 0h4M8 17H4m4 0h4"></path>
                            </g>
                        </svg>
                    </div>
                    <div
                        className={`editor-button ${editor.isActive('strike') ? 'active' : ''}`}
                        onClick={() => editor.chain().focus().toggleStrike().run()}
                        data-tippy-content="Strikethrough"
                    >
                        <svg fill="#000000" viewBox="0 0 64 64" version="1.1" xmlSpace="preserve" style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}>
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <rect id="Icons" x="-192" y="0" width="1280" height="800" style={{ fill: 'none' }}></rect>
                                <g id="Icons1" serifId="Icons">
                                    <g id="Strike">
                                        <rect id="Strike-line" serifId="Strike line" x="10.01" y="30.17" width="44.15" height="2.988"></rect>
                                        <rect x="10.01" y="30.17" width="44.15" height="2.988"></rect>
                                        <path id="Strike1" serifId="Strike" d="M46.396,35.711c0.414,0.482 0.779,0.983 1.093,1.504c1.06,1.756 1.59,3.823 1.59,6.2c0,3.791 -1.478,6.827 -4.433,9.107c-2.955,2.281 -6.907,3.422 -11.854,3.422c-3.212,0 -6.21,-0.616 -8.994,-1.848c-2.785,-1.231 -4.931,-2.917 -6.441,-5.059c-1.51,-2.142 -2.265,-4.572 -2.265,-7.292l6.2,0c0,2.827 1.044,5.059 3.132,6.698c2.088,1.638 4.877,2.457 8.368,2.457c3.256,0 5.75,-0.664 7.485,-1.992c1.735,-1.327 2.602,-3.137 2.602,-5.429c0,-2.291 -0.803,-4.063 -2.409,-5.316c-1.057,-0.824 -2.68,-1.642 -4.868,-2.452l10.794,0Zm-27.382,-8.094c-1.629,-1.974 -2.444,-4.3 -2.444,-6.978c0,-3.683 1.472,-6.73 4.417,-9.139c2.944,-2.409 6.773,-3.614 11.484,-3.614c3.212,0 6.077,0.621 8.593,1.863c2.517,1.242 4.465,2.956 5.847,5.14c1.381,2.185 2.072,4.572 2.072,7.164l-6.2,0c0,-2.827 -0.9,-5.049 -2.699,-6.666c-1.799,-1.617 -4.336,-2.425 -7.613,-2.425c-3.041,0 -5.413,0.669 -7.115,2.007c-1.703,1.339 -2.554,3.197 -2.554,5.574c0,1.906 0.808,3.518 2.425,4.835c0.966,0.786 2.337,1.533 4.112,2.239l-10.325,0Z" style={{ fillRule: 'nonzero' }}></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div
                        className={`editor-button ${editor.isActive('paragraph') ? 'active' : ''}`}
                        onClick={() => editor.chain().focus().setParagraph().run()}
                        data-tippy-content="Paragraph"
                    >
                        Paragraph
                    </div>
                    <div
                        className={`editor-button ${editor.isActive('heading', { level: 1 }) ? 'active' : ''}`}
                        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                        data-tippy-content="Heading 1"
                    >
                        <svg viewBox="0 0 24 24" version="1.1" fill="#000000">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <title>Heading_1_line</title>
                                <g id="页面-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g id="Editor" transform="translate(-432.000000, 0.000000)" fillRule="nonzero">
                                        <g id="Heading_1_line" transform="translate(432.000000, 0.000000)">
                                            <path
                                                d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                                                id="MingCute"
                                                fillRule="nonzero"
                                            />
                                            <path
                                                d="M13,3 C13.51285,3 13.9355092,3.38604429 13.9932725,3.88337975 L14,4 L14,20 C14,20.5523 13.5523,21 13,21 C12.48715,21 12.0644908,20.613973 12.0067275,20.1166239 L12,20 L12,13 L5,13 L5,20 C5,20.5523 4.55228,21 4,21 C3.48716857,21 3.06449347,20.613973 3.0067278,20.1166239 L3,20 L3,4 C3,3.44772 3.44772,3 4,3 C4.51283143,3 4.93550653,3.38604429 4.9932722,3.88337975 L5,4 L5,11 L12,11 L12,4 C12,3.44772 12.4477,3 13,3 Z M19.0001,13.5188 L19.0001,20.0001 C19.0001,20.5524 18.5524,21.0001 18.0001,21.0001 C17.4478,21.0001 17.0001,20.5524 17.0001,20.0001 L17.0001,15.3662 C16.5471,15.6283 15.9624,15.4963 15.6681,15.0548 C15.3617,14.5953 15.4859,13.9744 15.9454,13.668 L17.4299,12.6784 C18.101,12.2309 19.0001,12.7121 19.0001,13.5188 Z"
                                                id="形状"
                                                fill="#000000"
                                            />
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div
                        className={`editor-button ${editor.isActive('heading', { level: 2 }) ? 'active' : ''}`}
                        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                        data-tippy-content="Heading 2"
                    >
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <g id="Edit / Heading_H2">
                                    <path
                                        id="Vector"
                                        d="M15 12.5V12C15 10.3431 16.3431 9 18 9H18.1716C19.7337 9 20.9996 10.2665 20.9996 11.8286C20.9996 12.5788 20.702 13.2982 20.1716 13.8286L15 19.0002L21 19M3 5V12M3 12V19M3 12H11M11 5V12M11 12V19"
                                        stroke="#000000"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div
                        className={`editor-button ${editor.isActive('bulletList') ? 'active' : ''}`}
                        onClick={() => editor.chain().focus().toggleBulletList().run()}
                        data-tippy-content="Bullet List"
                    >
                        <svg viewBox="0 -3.5 29 29" version="1.1" fill="#000000">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <title>bullet-list</title>
                                <desc>Created with Sketch Beta.</desc>
                                <defs></defs>
                                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g id="Icon-Set-Filled" transform="translate(-571.000000, -210.000000)" fill="#000000">
                                        <path d="M598,227 L582,227 C580.896,227 580,227.896 580,229 C580,230.104 580.896,231 582,231 L598,231 C599.104,231 600,230.104 600,229 C600,227.896 599.104,227 598,227 L598,227 Z M598,219 L582,219 C580.896,219 580,219.896 580,221 C580,222.104 580.896,223 582,223 L598,223 C599.104,223 600,222.104 600,221 C600,219.896 599.104,219 598,219 L598,219 Z M582,215 L598,215 C599.104,215 600,214.104 600,213 C600,211.896 599.104,211 598,211 L582,211 C580.896,211 580,211.896 580,213 C580,214.104 580.896,215 582,215 L582,215 Z M574,226 C572.343,226 571,227.343 571,229 C571,230.657 572.343,232 574,232 C575.657,232 577,230.657 577,229 C577,227.343 575.657,226 574,226 L574,226 Z M574,218 C572.343,218 571,219.343 571,221 C571,222.657 572.343,224 574,224 C575.657,224 577,222.657 577,221 C577,219.343 575.657,218 574,218 L574,218 Z M574,210 C572.343,210 571,211.343 571,213 C571,214.657 572.343,216 574,216 C575.657,216 577,214.657 577,213 C577,211.343 575.657,210 574,210 L574,210 Z" id="bullet-list"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div
                        className={`editor-button ${editor.isActive('orderedList') ? 'active' : ''}`}
                        onClick={() => editor.chain().focus().toggleOrderedList().run()}
                        data-tippy-content="Ordered List"
                    >
                        <svg fill="#000000" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path d="M3.59 3.03h12.2v1.26H3.59zm0 4.29h12.2v1.26H3.59zm0 4.35h12.2v1.26H3.59zM.99 4.79h.49V2.52H.6v.45h.39v1.82zm.87 3.88H.91l.14-.11.3-.24c.35-.28.49-.5.49-.79A.74.74 0 0 0 1 6.8a.77.77 0 0 0-.81.84h.52A.34.34 0 0 1 1 7.25a.31.31 0 0 1 .31.31.6.6 0 0 1-.22.44l-.87.75v.39h1.64zm-.36 3.56a.52.52 0 0 0 .28-.48.67.67 0 0 0-.78-.62.71.71 0 0 0-.77.75h.5a.3.3 0 0 1 .27-.32.26.26 0 1 1 0 .51H.91v.38H1c.23 0 .37.11.37.29a.29.29 0 0 1-.33.29.35.35 0 0 1-.36-.35H.21a.76.76 0 0 0 .83.8.74.74 0 0 0 .83-.72.53.53 0 0 0-.37-.53z"></path>
                            </g>
                        </svg>
                    </div>
                    <div className="color-picker-container" onClick={togglePickerVisibility}>
                        <div className="color-picker" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div style={{ backgroundColor: selectedColor }}></div>
                            {isPickerVisible && (
                                <div className="color-picker__swatches">
                                    {colors.map(color => (
                                        <div
                                            key={color.value}
                                            className="color-picker__swatch"
                                            style={{ backgroundColor: color.value }}
                                            onClick={() => handleColorClick(color)}
                                        >
                                            {selectedColor === color.value && (
                                                <div className="color-picker__selected"></div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}

                        </div>
                        <div className='colorPickerDroipDown'>
                            <svg
                                fill="#000000"
                                version="1.1"
                                id="Layer_1"
                                viewBox="0 0 330 330"
                                xmlSpace="preserve"
                            >
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path
                                        id="XMLID_225_"
                                        d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                                    />
                                </g>
                            </svg>
                        </div>
                    </div>
                     <div className="editor-button" style={{ position: "relative" }}>
                        <svg
                            fill="black"
                            version="1.1"
                            id="Layer_1"
                            viewBox="0 0 72 72"
                            enableBackground="new 0 0 72 72"
                            xmlSpace="preserve"
                            stroke="#6c6c6c"
                            onClick={toggleHyperLinkDrop}
                        >
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <g>
                                    <g>
                                        <path d="M18.321,69.07c-2.874,0-5.775-0.845-8.31-2.604l-0.534-0.371c-6.614-4.593-8.259-13.712-3.666-20.326l13.931-18.588 c2.183-3.146,5.522-5.292,9.361-5.984c3.839-0.694,7.717,0.152,10.921,2.377l0.534,0.37c2.72,1.888,4.735,4.676,5.675,7.85 c0.313,1.059-0.291,2.172-1.351,2.485c-1.058,0.311-2.171-0.29-2.485-1.351c-0.691-2.337-2.116-4.308-4.119-5.698l-0.534-0.37 c-2.328-1.617-5.146-2.231-7.931-1.727c-2.787,0.503-5.212,2.061-6.828,4.388L9.055,48.108 c-3.293,4.744-2.099,11.365,2.704,14.701l0.534,0.371c4.801,3.334,11.423,2.142,14.759-2.66l4.256-6.126 c0.631-0.905,1.875-1.129,2.784-0.501c0.906,0.631,1.131,1.877,0.501,2.784l-4.256,6.125C27.504,66.882,22.948,69.07,18.321,69.07 z"></path>
                                    </g>
                                    <g>
                                        <path d="M40.297,51.043c-2.877,0-5.784-0.844-8.323-2.606l-0.538-0.375c-2.718-1.888-4.731-4.674-5.669-7.845 c-0.313-1.06,0.292-2.172,1.351-2.485c1.063-0.313,2.173,0.291,2.485,1.351c0.69,2.335,2.114,4.305,4.117,5.696l0.538,0.375 c4.799,3.332,11.421,2.138,14.757-2.664l13.93-18.59c3.294-4.744,2.1-11.365-2.703-14.701l-0.53-0.365 c-2.332-1.621-5.147-2.232-7.936-1.731c-2.787,0.503-5.212,2.061-6.828,4.388l-4.255,6.125c-0.63,0.908-1.876,1.132-2.783,0.502 s-1.132-1.876-0.502-2.783l4.255-6.125c2.225-3.205,5.564-5.351,9.404-6.043c3.838-0.691,7.718,0.153,10.922,2.379l0.529,0.365 c6.62,4.598,8.264,13.717,3.67,20.33l-13.93,18.59C49.453,48.868,44.914,51.043,40.297,51.043z"></path>
                                    </g>
                                    <g>
                                        <path d="M52.76,33.106c-0.209,0-0.419-0.065-0.599-0.2c-0.442-0.331-0.532-0.958-0.2-1.399l0.548-0.73 c0.331-0.442,0.959-0.53,1.399-0.2c0.442,0.331,0.532,0.958,0.2,1.399l-0.548,0.73C53.364,32.969,53.064,33.106,52.76,33.106z"></path>
                                    </g>
                                    <g>
                                        <path d="M55.047,30.056c-0.209,0-0.419-0.065-0.599-0.2c-0.442-0.331-0.532-0.958-0.2-1.399l4.426-5.904 c1.061-1.528,1.471-3.414,1.134-5.28c-0.337-1.867-1.38-3.491-2.938-4.572l-0.343-0.237c-0.454-0.315-0.567-0.938-0.253-1.392 c0.313-0.454,0.936-0.568,1.392-0.253l0.344,0.238c1.997,1.387,3.334,3.468,3.766,5.86s-0.094,4.81-1.48,6.806l-4.447,5.934 C55.651,29.918,55.352,30.056,55.047,30.056z"></path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        {toggleShowHyperLinkDrop && <div className='hyperLinkDropDown'>
                            <input
                                type="text"
                                placeholder="URL"
                                value={urlToReplace}
                                onChange={(e) => setUrlToReplace(e.target.value)}
                            />
                            <input
                                type="text"
                                placeholder="Text"
                                value={replacementText}
                                onChange={(e) => setReplacementText(e.target.value)}
                            />
                            <button onClick={handleReplaceLinks}>Replace Links</button>
                        </div>}
                    </div>
                    <div className="editor-button" onClick={() => document.getElementById('image-upload').click()}>
                        <svg viewBox="0 0 24 24" fill="#6c6c6c" style={{}}><path d="M0 0h24v24H0V0z" fill="none" /><path d="M18 20H4V6h9V4H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-9h-2v9zm-7.79-3.17l-1.96-2.36L5.5 18h11l-3.54-4.71zM20 4V1h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V6h3V4h-3z" /></svg>
                        <input
                            type="file"
                            id="image-upload"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleImageUpload}
                        />
                    </div>
                   
                </div>
              
                <div className="shadedLoader-container">
                    {loading && <div className="shadedLoader"></div>}
                    <EditorContent editor={editor} />
                </div>
            </div>
            <div className="editor-content">
                <button className='updateStatus-btn' type="submit" onClick={handleSubmitComment}
                    disabled={smallLoading}>Comment</button>
            </div>
        </div>
    );
};

export default TextEditor;
